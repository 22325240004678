import React from 'react';
import BaseModal from './BaseModal';
import '../App.css';

const VALID_PROFILE_PIC = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 60, 61, 62, 63, 64, 65, 66, 67, 68, 80, 81, 82,
  100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 130, 131, 132, 133, 140, 141, 142, 143, 144, 145, 146,
  200, 201, 202, 203, 204, 220, 225, 281, 282, 283, 284, 290,
  300
]

class ProfilePicModal extends React.Component {
  render() {
    const { isOpen, onRequestClose, onSubmit } = this.props;
    console.log(VALID_PROFILE_PIC?.map(x => `/profile/profile-${x}.png`));

    return (
      <BaseModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title="Choose Profile Picture"
        content={
          <div className="form">
            {
              VALID_PROFILE_PIC?.map(x => {
                return (
                  <img
                    src={`/profile/profile-${x}.png`}
                    className={`profile-pic ${this.state?.selected === x ? 'selected' : ''}`}
                    onClick={() => this.setState({'selected': x})}
                    key={`profile-pic-${x}`}
                  />
                )
              })
            }
            <div className="button-container">
              <button onClick={() => onSubmit(this.state?.selected)}>Select</button>
            </div>
          </div>
        }
      >
      </BaseModal>
    );
  }
}

export default ProfilePicModal;
