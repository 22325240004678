import React from 'react';
import MediaDetailPanel from './MediaDetailPanel';
import SidePanel from './SidePanel';
import Filters from './Filters';
import Legend from './Legend';
import TimelineElement from './TimelineElement';
import EraIcon from './EraIcon';
import { filter_media } from './Common';
import { VariableSizeList } from 'react-window';



class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortField: 'sort_order',
      gridRef: React.createRef(),
    };

    this.setSortField = this.setSortField.bind(this);
    this.clearSelectedMediaId = this.clearSelectedMediaId.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // re-calculate the timeline heights whenever the order or filters change
    if (this.state?.gridRef?.current !== null && (prevState.sortField !== this.state.sortField || prevProps.filters !== this.props.filters)) {
      this.state.gridRef.current.resetAfterIndex(0, true);
    }
  }

  componentDidMount() {
    if (window) {
      window.addEventListener('resize', this.updateDimensions);
    }
  }
  componentWillUnmount() {
    if (window) {
      window.removeEventListener('resize', this.updateDimensions);
    }
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  clearSelectedMediaId() {
    this.setState({
      'selectedMediaId': null,
      'selectedMediaIdList': null,
    });
  }

  setSelectedMediaId(selectedMediaId) {
    // set selectedMediaId to the media id of the currently selected row,
    // and selectedData to any array of the selected media id plus any
    // other media ids from the same collection if the user is grouping by collection
    const data = this.props.data;
    const selectedRow = data.find(x => x.media_id === selectedMediaId);
    const selectedData = data.filter(x =>
      (((this.props.filters.includes('collectionFilter') && x.format === 'C')
        || (this.props.filters.includes('seasonFilter') && x.format === 'T'))
        && x.collection_title
        && x.collection_title === selectedRow?.collection_title)
      || x.media_id === selectedMediaId
    );
    const selectedMediaIdList = selectedData.map(x => x.media_id);
    this.setState({
      selectedMediaId,
      selectedMediaIdList,
    });
  }

  setSortField = (field) => {
    const sort_fields = ['release_date', 'sort_order', 'avg_rating'];

    if (sort_fields.includes(field) && document.getElementById(field).checked) {
      this.setState({ sortField: field });
    }
  }

  render() {
    if (!this.props.data || !this.state.gridRef) {
      return;
    }

    let data = filter_media(this.props.data, this.props.filters);
    const selectedRow = data.find(x => x.media_id === this.state.selectedMediaId);
    let searchText = false;

    if (this.props.showSidePanel !== false && document.getElementById('search-text')?.value) {
      searchText = document.getElementById('search-text').value;
    }

    // filter based on title, if the user is searching by title
    // apply search text here instead of App.js
    // because we don't want it to apply to any other pages
    if (searchText && searchText !== '') {
      data = data.filter(x => x.title.toLowerCase().includes(searchText.toLowerCase()));
    }

    if (this.state.sortField === 'release_date') {
      data.sort((a, b) => new Date(b['release_date'] || '2099-01-01') - new Date(a['release_date'] || '2099-01-01'));
    } else if (this.state.sortField === 'sort_order') {
      data.sort((a, b) => a[this.state.sortField] - b[this.state.sortField]);
    } else {
      data.sort((a, b) => (b[this.state.sortField] || 1) - (a[this.state.sortField] || 1));
    }

    const displayEraIcon = i => {
      return (i === 0 || data[i].era !== data[i - 1].era) 
        && data[i]?.era 
        && this.state?.sortField === 'sort_order' 
        && this.props.showSidePanel !== false
    }

    const getItemSize = index => (data[index].format === 'F' ? 50 : 32) + (displayEraIcon(index) ? 100 : 0);

    const Row = ({ index, style }) => (
      data[index] ?
        <div style={style} className="timeline-row">
          {
            displayEraIcon(index)
            ? <EraIcon data={data[index]} />
            : <div />
          }
          <TimelineElement
            data={data[index]}
            onClick={() => this.setSelectedMediaId(data[index].media_id)}
            aggregate={
              (this.props.filters.includes('collectionFilter') && data[index]?.format === 'C')
              || (this.props.filters.includes('seasonFilter') && data[index]?.format === 'T')
            }
            selected={data[index]?.media_id === this.state?.selectedMediaId}
          >
          </TimelineElement>
        </div>
        : <div />
    );

    return (
      <div>
        <MediaDetailPanel
          data={selectedRow}
          addRating={this.props.addRating}
          loggedIn={this.props.loggedIn}
          onClose={this.clearSelectedMediaId}
        >
        </MediaDetailPanel>

        {
          this.props.showSidePanel !== false
            ?
            <div className='side-panel-container'>
              <SidePanel
                title='Options & Filters'
                content={<Filters
                  filters={this.props.filters}
                  addFilter={this.props.addFilter}
                  searchText={searchText}
                  setSortField={this.setSortField}
                  sortField={this.state.sortField}
                />}
                enabled={this.props.showFilter}
                onRequestClose={this.props.toggleShowFilter}
              />
              <SidePanel
                title='Legend'
                content={<Legend />}
                enabled={this.props.showLegend}
                onRequestClose={this.props.toggleShowLegend}
              />
            </div>
            : <div />
        }

        <div className="App-timeline-rows">

          <VariableSizeList
            className="scroll-list"
            height={parseInt(this.state.height || window.innerHeight * 0.9)}
            itemCount={data.length}
            itemSize={getItemSize}
            width={this.state.width || window.innerWidth}
            overscanCount={2}
            ref={this.state.gridRef}
          >
            {Row}
          </VariableSizeList>

        </div >
      </div>
    );
  }
}

export default Timeline;
