import React from 'react';
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import './App.css';
import { ALL_FILTERS, eventGuideData, URL } from './Common';
import Methodology from './Methodology';
import PrivacyPolicy from './PrivacyPolicy';
import Profile from './Profile';
import Achievements from './Achievements';
import Blog from './Blog';
import Timeline from './Timeline';
import Footer from './Footer';
import Leaderboard from './Leaderboard';
import StartingPoints from './StartingPoints';
import LoginModal from './Modal/LoginModal';
import SignupModal from './Modal/SignupModal';
import PasswordResetModal from './Modal/PasswordResetModal';
import EventGuide from './EventGuide';
import MediaPage from './MediaPage';
import OmnibusGuide from './OmnibusGuide';
import SideNavBar from './SideNavBar';
import Icon from '@mdi/react';
import {
  mdiFilterCog,
  mdiNoteText,
  mdiAccount,
  mdiAccountPlus,
  mdiFormatListNumbered,
  mdiFrequentlyAskedQuestions,
  mdiBookOpenVariant,
  mdiPostOutline,
  mdiTimelineCheckOutline,
  mdiTrophy,
} from '@mdi/js';

const HEADER_OPTIONS = [
  { 'url': '', 'label': 'Timeline', 'icon': mdiTimelineCheckOutline },
  { 'url': 'blog', 'label': 'Reviews', 'icon': mdiPostOutline },
  { 'url': 'startingpoints', 'label': 'Starting Points', 'icon': mdiBookOpenVariant },
  { 'url': 'methodology', 'label': 'FAQs', 'icon': mdiFrequentlyAskedQuestions },
  { 'url': 'leaderboard', 'label': 'Leaderboard', 'icon': mdiFormatListNumbered },
  { 'url': 'achievements', 'label': 'Achievements', 'icon': mdiTrophy },
];

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: ['collectionFilter', 'hideShorts'],
      showFilter: window.innerWidth > 1300,
    };

    this.addRating = this.addRating.bind(this);
    this.toggleShowLogin = this.toggleShowLogin.bind(this);
    this.toggleShowSignup = this.toggleShowSignup.bind(this);
    this.toggleShowPasswordReset = this.toggleShowPasswordReset.bind(this);
    this.toggleShowFilter = this.toggleShowFilter.bind(this);
    this.toggleShowLegend = this.toggleShowLegend.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  toggleShowLogin = () => {
    this.setState({
      showLogin: !this.state.showLogin,
    });
  };

  toggleShowSignup = () => {
    this.setState({
      showSignup: !this.state.showSignup,
    });
  };

  toggleShowPasswordReset = () => {
    this.setState({
      showPasswordReset: !this.state.showPasswordReset,
      showLogin: false,
      showSignup: false,
    });
  };

  toggleShowFilter = () => {
    this.setState({
      showFilter: !this.state.showFilter,
    });
  }

  toggleShowLegend = () => {
    this.setState({
      showLegend: !this.state.showLegend,
    });
  }

  addFilter = () => {
    // update the list of applied filters
    const filters = [];

    ALL_FILTERS.forEach(f => {
      if (document.getElementById(f.name).checked) {
        filters.push(f.name);
      }
    });

    this.setState({ filters });
  }

  handleLogin = (path) => {
    // login an existing user or register and login a new user
    const username = document.getElementById('uname').value;
    const password = document.getElementById('pass').value;
    let requestBody;
    let url;
    let toggleFunc;

    if (!username || !password) {
      this.setState({ 'signupErr': 'Please enter usera!' });
      return;
    }

    if (path === 'login') {
      url = `${URL}/login`;
      toggleFunc = this.toggleShowLogin;

      requestBody = JSON.stringify({
        username: username,
        password: password,
      })
    }
    else if (path === 'signup') {
      url = `${URL}/signup`;
      toggleFunc = this.toggleShowSignup;
      const email = document.getElementById('email').value;

      // check that both password fields match
      const passwordCheck = document.getElementById('pass-confirm').value;
      if (passwordCheck !== password) {
        this.setState({ 'signupErr': 'Entered passwords do not match!' });
        return;
      }

      requestBody = JSON.stringify({
        username: username,
        password: password,
        email: email,
      })
    }
    else {
      return;
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: requestBody
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw Error(res.error);
        }
        this.setState({ username, signupErr: null });
        // load user ratings after the user logged in
        this.loadUserRating();
        toggleFunc();
      })
      .catch(error => {
        this.setState({ signupErr: error.message });
      });
  };

  requestReset = () => {
    // request a password reset email
    const username = document.getElementById('reset-uname').value;

    if (!username) {
      this.setState({ 'signupErr': 'Please enter your username to request password reset!' });
      return;
    }

    const url = `${URL}/request_password_reset`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify({
        username: username,
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw Error(res.error);
        }
      })
      .catch(error => {
        this.setState({ signupErr: error.message });
      });
  };

  passwordReset = () => {
    // reset a password after receiving the password reset email
    const username = document.getElementById('reset-uname').value;
    const password = document.getElementById('reset-pass').value;
    const passwordCheck = document.getElementById('reset-pass-confirm').value;
    const token = document.getElementById('reset-token').value;

    if (!username) {
      this.setState({ 'signupErr': 'Please enter your username, token, and new password!' });
      return;
    }

    // check that both password fields match
    if (passwordCheck !== password) {
      this.setState({ 'signupErr': 'Entered passwords do not match!' });
      return;
    }

    const url = `${URL}/reset_password`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify({
        username: username,
        password: password,
        token: token,
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw Error(res.error);
        }
        else {
          this.setState({ username, signupErr: null });
          this.toggleShowPasswordReset();
        }
      })
      .catch(error => {
        this.setState({ signupErr: error.message });
      });
  };

  componentDidMount() {
    setInterval(() => {
      if (this.state && window.location.href !== this.state.url) {
        const newView = window.location.href.split('/')[3];
        this.setState({ 'view': newView, 'url': window.location.href });
      }
    }, 100);

    this.loadTimelineData();
    this.checkSession();
    this.loadBlog();
  }

  loadBlog() {
    fetch(`${URL}/blog`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.text())
      .then(res => {
        const result = JSON.parse(res);
        this.setState({
          blogEntries: [...this.state.blogEntries || [], ...result.blogEntries],
        });
      })
      .catch(err => err);
  }

  checkSession() {
    fetch(`${URL}/check_session`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: "include",
    })
      .then(res => res.json())
      .then(res => {
        if (res.username) {
          // set the user's username
          this.setState({ username: res.username });
        } else {
          // undefined means username hasn't been checked yet,
          // null means not logged in
          this.setState({ username: null });
        }
      });
  }

  loadTimelineData() {
    fetch(`${URL}/media`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.text())
      .then(res => {
        this.setState({ data: JSON.parse(res) });
        // load ratings after loading the timeline data
        this.loadUserRating();
      })
      .catch(err => err);
  }

  loadUserRating() {
    fetch(`${URL}/user_rating`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: "include",
    })
      .then(res => res.text())
      .then(res => {
        // update the client side data to have all the user's ratings
        const newData = [...this.state.data];
        const newRatings = JSON.parse(res);

        // a media id of 1 has an index of 0
        if (newRatings?.length) {
          newRatings.forEach(rating => {
            const index = newData.findIndex(x => x.media_id === rating.media_id);
            if (index in newData) {
              newData[index] = Object.assign(newData[index], rating);
            }
          })
          this.setState({ data: newData });
        }
      })
      .catch(err => err);
  }

  addRating(mediaIdList, rating) {
    if (!this.state.username || !rating || !mediaIdList) {
      return;
    }

    fetch(`${URL}/media_rating`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify({
        mediaIdList: mediaIdList,
        rating: rating,
      })
    })
      .then(res => res.text())
      .then(res => {
        // update the client side data to have the user's newly entered rating
        const newData = [...this.state.data];
        const updatedRows = JSON.parse(res);

        // a media id of 1 has an index of 0
        updatedRows.forEach(updatedRow => {
          const index = newData.findIndex(x => x.media_id === updatedRow.media_id);
          newData[index] = Object.assign({}, newData[index], updatedRow);
          newData[index].user_rating = rating;
        });
        this.setState({ data: newData });
      })
      .catch(err => err);
  }

  render() {
    let data = this.state.data || [];
    const view = window.location.href.split('/')[3];
    const username = this.state.username;

    return (
      <div className="App">
        <BrowserRouter>
          <header className="App-header">
            <h1 className="desktop-only">Star Wars Interactive Canon Timeline</h1>
            {
              view === ''
                ?
                <div className='App-header-box mobile-only' style={{ flexGrow: 1 }}>
                  {
                    <div className="menu-icon menu-sub-icon" onClick={this.toggleShowFilter}>
                      <Icon className='icon' path={mdiFilterCog} />
                      Filters
                    </div>
                  }
                  {
                    <div className="menu-icon menu-sub-icon" onClick={this.toggleShowLegend}>
                      <Icon className='icon' path={mdiNoteText} />
                      Legend
                    </div>
                  }
                </div>
                : <div style={{ display: 'none' }} />
            }
            <nav className='App-header-box' style={{ flexGrow: 2 }}>
              {
                HEADER_OPTIONS.map(x => {
                  return (
                    <NavLink
                      className={`menu-icon ${x.url === 'achievements' ? 'desktop-only' : ''}`}
                      to={`/${x.url}`}
                      key={`app-header-row-${x.url}`}
                    >
                      <Icon className='icon' path={x.icon} />
                      {x.label}
                      <div className={`App-header-underline ${x.url === view ? 'selected' : ''}`} />
                    </NavLink>
                  )
                })
              }
              {
                username
                  ?
                  <NavLink
                    className="menu-icon"
                    to={`/profile/${username}`}
                  >
                    <Icon className='icon' path={mdiAccount} />
                    My Profile
                    <div className={`App-header-underline ${view.includes('profile') ? 'selected' : ''}`} />
                  </NavLink>
                  :
                  <div
                    className="menu-icon"
                    onClick={() => {
                      this.toggleShowLogin();
                    }}
                  >
                    <Icon className='icon' path={mdiAccount} />
                    Log In
                  </div>
              }
              {
                username
                  ?
                  <div />
                  :
                  <div
                    className="menu-icon"
                    onClick={() => {
                      this.toggleShowSignup();
                    }}
                  >
                    <Icon className='icon' path={mdiAccountPlus} />
                    Register
                  </div>
              }
            </nav>
          </header>
          <img
            loading="lazy"
            src='/img/background.png'
            style={{ position: 'absolute', top: '10%', height: '90%', width: '100%' }}
            alt="Starry night sky background"
          />
          <Routes>
            <Route path="/" element={
              <div className='App-body'>
                <Timeline
                  data={data}
                  loggedIn={username ? true : false}
                  toggleShowLegend={this.toggleShowLegend}
                  toggleShowFilter={this.toggleShowFilter}
                  showLegend={this.state.showLegend}
                  showFilter={this.state.showFilter}
                  searchText={this.state.searchText}
                  addRating={this.addRating}
                  addFilter={this.addFilter}
                  filters={this.state.filters}
                />
              </div>
            } />
            <Route path="/blog/*" element={
              <div className='App-body'>
                <div className='blog'>
                  <Blog
                    blogEntries={this.state.blogEntries}
                    data={data}
                    addRating={this.addRating}
                    loggedIn={username ? true : false}
                    selectedBlogEntry={this.state.selectedBlogEntry}
                  />
                  <SideNavBar blogEntries={this.state.blogEntries}data={this.state.data} />
                </div>
                <Footer />
              </div>
            } />
            <Route path="/startingpoints" element={
              <div className='App-body'>
                <div className='blog'>
                  <StartingPoints
                    data={data}
                    addRating={this.addRating}
                    loggedIn={username ? true : false}
                  />
                  <SideNavBar blogEntries={this.state.blogEntries}data={this.state.data} />
                </div>
                <Footer />
              </div>
            } />
            <Route path="/methodology" element={
              <div className='App-body'>
                <div className='blog'>
                  <Methodology />
                  <SideNavBar blogEntries={this.state.blogEntries}data={this.state.data} />
                </div>
                <Footer />
              </div>
            } />
            <Route path="/leaderboard" element={
              <div className='App-body'>
                <Leaderboard data={data} username={username} />
                <Footer />
              </div>
            } />
            <Route path="/achievements" element={
              <div className='App-body'>
                <Achievements data={data} />
                <Footer />
              </div>
            } />
            <Route path="/privacypolicy" element={
              <div className='App-body'>
                <PrivacyPolicy />
                <Footer />
              </div>
            } />
            <Route path="/omnibus_guide/*" element={
              <div className='App-body'>
                <div className='blog'>
                  <OmnibusGuide
                    data={this.state.data}
                    username={this.state.username}
                    loggedIn={username ? true : false}
                    addRating={this.addRating}
                  />
                  <SideNavBar blogEntries={this.state.blogEntries} data={this.state.data} />
                </div>
                <Footer />
              </div>
            } />
            <Route path="/profile/*" element={
              <div className='App-body'>
                <Profile
                  data={this.state.data}
                  username={this.state.username}
                />
                <Footer />
              </div>
            } />
            {
              Object.entries(eventGuideData).map(([path, guideData]) => {
                return (
                  <Route path={`/${path}`} key={`route-${path}`} element={
                    <div className='App-body'>
                      <EventGuide
                        data={data}
                        loggedIn={username ? true : false}
                        addRating={this.addRating}
                        guideData={guideData}
                      />
                    </div>
                  } />)
              })
            }
            <Route path="content/*" element={
              <div className='App-body'>
                <div className='blog'>
                  <MediaPage
                    data={data}
                    loggedIn={username ? true : false}
                    addRating={this.addRating}
                    username={username}
                  />
                  <SideNavBar blogEntries={this.state.blogEntries} data={this.state.data} />
                </div>
                <Footer />
              </div>
            } />
          </Routes>
        </BrowserRouter>

        <LoginModal
          onRequestClose={this.toggleShowLogin}
          isOpen={this.state.showLogin}
          handleLogin={this.handleLogin}
          signupErr={this.state.signupErr}
          toggleShowPasswordReset={this.toggleShowPasswordReset}
        />
        <SignupModal
          onRequestClose={this.toggleShowSignup}
          isOpen={this.state.showSignup}
          handleLogin={this.handleLogin}
          signupErr={this.state.signupErr}
        />
        <PasswordResetModal
          onRequestClose={this.toggleShowPasswordReset}
          isOpen={this.state.showPasswordReset}
          onSubmit={this.passwordReset}
          signupErr={this.state.signupErr}
          requestReset={this.requestReset}
        />
      </div>
    );
  }
}

export default App;
